import { DateTime } from 'luxon'

import api from '../api'
import analytics from '../services/analytics'
import getUserDisplayName from '../utils/getUserDisplayName'

export const LOAD_PROFILE = 'profile/LOAD_PROFILE'
export const PROFILE_LOADED = 'profile/PROFILE_LOADED'
export const PROFILE_FAILED = 'profile/PROFILE_FAILED'
const SAVE_PROFILE = 'profile/SAVE_PROFILE'
const SAVE_PROFILE_SUCCESS = 'profile/SAVE_PROFILE_SUCCESS'
const SAVE_PROFILE_FAILURE = 'profile/SAVE_PROFILE_FAILURE'
const SAVE_PASSWORD = 'profile/SAVE_PASSWORD'
const SAVE_PASSWORD_SUCCESS = 'profile/SAVE_PASSWORD_SUCCESS'
const SAVE_PASSWORD_FAILURE = 'profile/SAVE_PASSWORD_FAILURE'
const NEW_PHONE_VERIFIED = 'profile/NEW_PHONE_VERIFIED'

const initialState = {
  loadingProfile: true,
  failedToLoad: false,
  savingProfile: false,
  savingPassword: false,
  givenName: undefined,
  familyName: undefined,
  name: undefined,
  displayName: undefined,
  email: undefined,
  phone: undefined,
  phoneVerified: undefined,
  company: undefined,
  companyStreet: undefined,
  companyCity: undefined,
  companyState: undefined,
  companyZip: undefined,
  source: undefined,
  photoURL: undefined,
  primaryCard: undefined,
  creditCardList: undefined,
  professionType: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_PROFILE:
      return { ...state, loadingProfile: true, failedToLoad: false }
    case PROFILE_LOADED:
      return { ...state, ...payload, loadingProfile: false, failedToLoad: false }
    case PROFILE_FAILED:
      return { ...initialState, failedToLoad: true }
    case SAVE_PROFILE:
      return { ...state, savingProfile: true }
    case SAVE_PROFILE_SUCCESS:
      return { ...state, savingProfile: false, ...payload }
    case SAVE_PROFILE_FAILURE:
      return { ...state, savingProfile: false }
    case SAVE_PASSWORD:
      return { ...state, savingPassword: true }
    case SAVE_PASSWORD_SUCCESS:
      return { ...state, savingPassword: false }
    case SAVE_PASSWORD_FAILURE:
      return { ...state, savingPassword: false }
    case NEW_PHONE_VERIFIED:
      return { ...state, phoneVerified: true }
    default:
      return state
  }
}

export const clearProfile = () => async dispatch => dispatch({ type: PROFILE_FAILED })

const formattedUTCDate = timestamp => DateTime.fromMillis(timestamp, { zone: 'utc' }).toFormat('yyyy-MM-dd')

export const loadProfile = _profile => async (dispatch) => {
  dispatch({ type: LOAD_PROFILE })
  try {
    const profile = _profile || (await api.profile.load())
    analytics.identify(profile.userId.split(':')[1], {
      groupProperties: {
        team_id: profile.team_id
      }
    })
    let subscriptionTitle = 'Free Trial'
    let role = ''
    let teamId = ''
    const { doesNotExist, subscriptions, team } = profile
    if (doesNotExist !== true) {
      if (subscriptions?.length) {
        subscriptionTitle = subscriptions[0]?.orderDisplayTitle || 'Free Trial'
      }
      if (team) {
        teamId = team.id
        const member = team.members?.find(m => m.id === profile.userId)
        if (member) {
          // eslint-disable-next-line prefer-destructuring
          role = member.role
        }
      }

      const trialPeriod = subscriptions?.[0]?.limits?.free?.period?.ms
      const { signUpTimestamp } = profile
      const { start_time } = profile.subscriptions?.[0] || {}

      const trialEndDateTimestamp = typeof trialPeriod !== 'undefined' ? (start_time || signUpTimestamp) + trialPeriod : null
      const trialEndDate = trialEndDateTimestamp ? formattedUTCDate(trialEndDateTimestamp) : null

      const daysUntilOrPastTrialEnd = trialEndDateTimestamp
        ? DateTime.fromMillis(trialEndDateTimestamp, { zone: 'utc' }).diffNow('days').days
        : null

      const lastPlanPurchaseDate = profile.subscriptions?.[0]?.purchase_provider ? formattedUTCDate(profile.subscriptions?.[0]?.start_time) : null

      const daysSinceLastPlanPurchaseDate = lastPlanPurchaseDate
        ? DateTime.fromMillis(profile.subscriptions?.[0]?.start_time, { zone: 'utc' }).diffNow('days').negate().days
        : null

      analytics.setProperties({
        profileProperties: {
          trial_end_date: trialEndDate,
          trial_end_date_timestamp: trialEndDateTimestamp,
          days_until_or_past_trial_end: daysUntilOrPastTrialEnd,
          profession_type: profile?.professionType || '',
          last_plan_purchase_date: lastPlanPurchaseDate,
          last_plan_purchase_date_timestamp: lastPlanPurchaseDate ? profile.subscriptions?.[0]?.start_time : null,
          days_since_last_plan_purchase_date: daysSinceLastPlanPurchaseDate,
          lifetime_project_total: profile?.counts?.lifetime?.code_reports || 0,
          user_status: `subscriber${subscriptionTitle}`,
          team_id: teamId,
          team_position: role
        }
      })
    }
    let primaryCard
    if (profile.customer && profile.customer.default_source) {
      primaryCard = profile.customer.sources.data.find(card => card.id === profile.customer.default_source)
    }
    profile.primaryCard = primaryCard
    let creditCardList
    if (profile.customer && profile.customer.sources) {
      creditCardList = profile.customer.sources.data
    }
    profile.creditCardList = creditCardList
    if (!profile.team_id) {
      profile.team_id = undefined
      profile.team = undefined
    }
    profile.displayName = getUserDisplayName(profile)
    dispatch({ type: PROFILE_LOADED, payload: profile })

    return profile
  } catch (e) {
    dispatch({ type: PROFILE_FAILED })
  }
}

export const savePassword = (currentPassword, newPassword) => async (dispatch) => {
  dispatch({ type: SAVE_PASSWORD })
  try {
    const response = await api.profile.changePassword(currentPassword, newPassword)
    dispatch({ type: SAVE_PASSWORD_SUCCESS })

    return response
  } catch (e) {
    dispatch({ type: SAVE_PASSWORD_FAILURE })
    throw e
  }
}

export const save = profile => async (dispatch) => {
  dispatch({ type: SAVE_PROFILE })
  try {
    // eslint-disable-next-line no-underscore-dangle
    const _profile = await api.profile.save(profile)
    _profile.givenName = _profile.givenName || ''
    _profile.familyName = _profile.familyName || ''
    _profile.phone = _profile.phone || ''
    dispatch({ type: SAVE_PROFILE_SUCCESS, payload: _profile })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('profile save failed', e)
    dispatch({ type: SAVE_PROFILE_FAILURE })
  }
}

export const requestVerifyNewPhoneCode = () => async () => api.profile.requestPhoneVerificationCode()

export const submitNewPhoneVerificationCode = code => async (dispatch) => {
  const profile = await dispatch(loadProfile())
  const { response } = await api.profile.submitPhoneVerificationCode(code, profile)
  if (response === 'SUCCESS') {
    dispatch({ type: NEW_PHONE_VERIFIED })
  }

  return dispatch(loadProfile())
}
