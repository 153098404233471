import React from 'react'
import { Route } from 'react-router-dom'
import AuthenticatedRoute from '../../components/atoms/AuthenticatedRoute'
import withCookies from '../../components/HOC/withCookies'
import { ROUTES_NAMES } from '../../constants'
import MainLayout from '../../layouts'
import NotFound from '../../routes/notFound'
import { quickLoadable } from '../helpers.routers'
import ProfileRouter from './ProfileRouter'

const {
  dashboard: { route: dashboardRoute },
  dashboardNew: { route: dashboardNewRoute },
  integrations: { route: integrationsRoute },
  subscription: { route: subscriptionRoute },
  mandatoryProfile: { route: mandatoryProfileRoute },
  profile: { route: profileRoute },
  plansPurchase: { route: plansPurchaseRoute },
  plans: { route: plansRoute },
  businessPlans: { route: businessPlansRoute },
  addressLookup: { route: addressLookupRoute },
  myTeam: { route: myTeamRoute },
  editTeam: { route: editTeamRoute },
  editTeamMember: { route: editTeamMemberRoute },
  feedback: { route: feedbackRoute },
  manufacturerSelection: { route: manufacturerSelectionRoute },
  permitFee: { route: permitFeeRoute },
  search: { route: searchRoute },
  propertyType: { route: propertyTypeRoute },
  singlePurchase: { route: singlePurchaseRoute },
  calculators: { route: calculators },
  tools: { route: tools }
} = ROUTES_NAMES

const Dashboard = quickLoadable(() => import(/* webpackChunkName: "dashboard" */ '../../routes/dashboard'))

const New = quickLoadable(() => import(/* webpackChunkName: "dashboard" */ '../../routes/dashboard/new'))

const Plans = quickLoadable(() => import(/* webpackChunkName: "plans" */ '../../routes/plans'))

const BusinessPlans = quickLoadable(() => import(/* webpackChunkName: "plans" */ '../../routes/businessPlans'))

const Integrations = quickLoadable(() => import(/* webpackChunkName: "integrations" */ '../../routes/integrations'))

const MandatoryProfile = quickLoadable(() => import(/* webpackChunkName: "profile" */ '../../routes/mandatoryProfile'))

const Profile = quickLoadable(() => import(/* webpackChunkName: "profile" */ './ProfileRouter'))

const Subscription = quickLoadable(() => import(/* webpackChunkName: "subscription" */ '../../routes/subscription'))

const AddressLookup = quickLoadable(() => import(/* webpackChunkName: "stateselection" */ '../../routes/addressLookup'))

const MyTeam = quickLoadable(() => import(/* webpackChunkName: "myTeam" */ '../../routes/myTeam'))

const EditTeam = quickLoadable(() => import(/* webpackChunkName: "editTeam" */ '../../routes/editTeam'))

const EditTeamMember = quickLoadable(() => import(/* webpackChunkName: "editTeamMember" */ '../../routes/editTeamMember'))

const Feedback = quickLoadable(() => import(/* webpackChunkName: "feedback" */ '../../routes/feedback'))

const ReportPropertyType = quickLoadable(() => import(/* webpackChunkName: "reportPropertyType" */ '../../routes/reportPropertyType'))

const ManufacturerSelection = quickLoadable(() => import(/* webpackChunkName: "ManufacturerSelection" */ '../../routes/manufacturerSelection'))

const PermitFee = quickLoadable(() => import(/* webpackChunkName: "PermitFee" */ '../../routes/permitFee'))

const PlanPurchase = quickLoadable(() => import(/* webpackChunkName: "PlanPurchase" */ '../../routes/planPurchase'))

const SingleProjectPurchase = quickLoadable(() => import(/* webpackChunkName: "SingleProjectPurchase" */ '../../routes/singleProjectPurchase'))

const Calculators = quickLoadable(() => import(/* webpackChunkName: "Calculators" */ '../../routes/tools'))
const IWSCalculator = quickLoadable(() => import(/* webpackChunkName: "WasteCalculator" */ '../../routes/tools/iwsCalculator'))
const WasteCalculator = quickLoadable(() => import(/* webpackChunkName: "WasteCalculator" */ '../../routes/tools/wasteCalculator'))
const VentilationCalculator = quickLoadable(() => import(/* webpackChunkName: "VentilationCalculator" */ '../../routes/tools/ventilationCalculator'))
const Tools = quickLoadable(() => import(/* webpackChunkName: "Calculators" */ '../../routes/toolsMenu'))

const PAGES = [
  { path: dashboardRoute, component: Dashboard },
  { path: `${integrationsRoute}/:id`, component: Integrations },
  { path: integrationsRoute, component: Integrations },
  { path: subscriptionRoute, component: Subscription },
  { path: mandatoryProfileRoute, component: MandatoryProfile },
  { path: profileRoute, component: Profile },
  { path: plansPurchaseRoute, component: PlanPurchase },
  { path: plansRoute, component: Plans },
  { path: businessPlansRoute, component: BusinessPlans },
  { path: addressLookupRoute, component: AddressLookup },
  { path: myTeamRoute, component: MyTeam },
  { path: editTeamRoute, component: EditTeam },
  { path: editTeamMemberRoute, component: EditTeamMember },
  { path: feedbackRoute, component: Feedback },
  {
    path: `${manufacturerSelectionRoute}/:reportNumber`,
    component: ManufacturerSelection
  },
  { path: `${permitFeeRoute}/:reportNumber`, component: PermitFee },
  { path: searchRoute, component: AddressLookup },
  { path: propertyTypeRoute, component: ReportPropertyType },
  { path: singlePurchaseRoute, component: SingleProjectPurchase },
  { path: `${calculators}/iws/:reportNumber`, component: IWSCalculator },
  { path: `${calculators}/waste/:reportNumber`, component: WasteCalculator },
  { path: `${calculators}/ventilation/:reportNumber`, component: VentilationCalculator },
  { path: `${calculators}/:type/:reportNumber`, component: Calculators },
  { path: `${calculators}/iws`, component: IWSCalculator },
  { path: `${calculators}/waste`, component: WasteCalculator },
  { path: `${calculators}/ventilation`, component: VentilationCalculator },
  { path: `${calculators}/:type`, component: Calculators },
  { path: tools, component: Tools },
  { path: dashboardNewRoute, component: New }
]

const AuthenticatedRoutes = (
  <Route element={<MainLayout />}>
    {PAGES.map(({ path, component, ...props }) => (
      <Route path={path} key={path} element={<AuthenticatedRoute component={withCookies(component)} />} {...props} />
    ))}
    {ProfileRouter}
    <Route component={NotFound} />
  </Route>
)

export default AuthenticatedRoutes
