import { Box, Link, LinkText, Text } from '@oneclickdata/components'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

function FooterElement({ children, ...props }) {
  return (
    <Box $base-flexDirection="column" $lg-flexDirection="row" $base-gap={0} $lg-gap="$4" alignItems="center" {...props}>
      {children}
    </Box>
  )
}

const Footer = ({ transparent }) => (
  <Box
    p="$5"
    $base-flexDirection="column"
    $lg-flexDirection="row"
    $base-gap="$4"
    $lg-gap={0}
    justifyContent="space-evenly"
    backgroundColor={transparent ? 'transparent' : '$backgroundSite'}>
    <FooterElement>
      <Text color="$neutral600">
        &copy;
        {new Date().getFullYear()} Code Reports by OneClick.
      </Text>
      <Text color="text.500">All Rights Reserved.</Text>
    </FooterElement>
    <FooterElement>
      <Link testID="qa-termsofservice-link" tabIndex="-1" href="https://www.oneclickcode.com/terms-of-use" isExternal>
        <LinkText>Terms of Service</LinkText>
      </Link>
      <Link testID="qa-termsofsale-link" tabIndex="-1" href="https://www.oneclickcode.com/terms-of-sale-and-subscription-agreement" isExternal>
        <LinkText>Terms of Sale</LinkText>
      </Link>
      <Link testID="qa-privacypolicy-link" tabIndex="-1" href="https://www.oneclickcode.com/privacy-policy" isExternal>
        <LinkText>Privacy Policy</LinkText>
      </Link>
    </FooterElement>
    <FooterElement>
      <Link testID="qa-helpcenter-link" tabIndex="-1" href="https://help.oneclickcode.com/" isExternal>
        <LinkText>Help Center</LinkText>
      </Link>
      <RouterLink to="/feedback">
        <Link testID="qa-contact-link" tabIndex="-1">
          <LinkText>Contact</LinkText>
        </Link>
      </RouterLink>
    </FooterElement>
  </Box>
)

export default Footer
