/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "occapi",
            "endpoint": "https://rt20msa2z4.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "occ-account-hashes-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-api-keys-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-api-partners-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-climate-details-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-config-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-consumer-teams-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-counters-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-entity-associations-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-form-lists-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-global-events-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-id-account-associations-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-iecc-rvalues-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-last-user-update-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-muni-details-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-muni-lookup-errors-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-partner-reports-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-permit-calc-logs-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-products-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-profile-aliases-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-profiles-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-project-municipal-data-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-reports-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-sso-domains-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-sso-providers-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-team-invites-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-team-members-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-transactions-beta",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-user-api-keys-beta",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
