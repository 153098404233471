import { getStableUUID } from '../../customHooks/useStableUUID'
import { env } from '../../envTest'
import { firebaseAnalytics } from './firebaseConfig'

// Firebase Handler
export const firebaseHandler = {
  name: 'Firebase',
  logEvent: (name, params, options) => firebaseAnalytics.logEvent(name, params, options),
  identify: (id, properties) => {
    firebaseAnalytics.setUserId(id)
    firebaseHandler.setProperties(properties)
  },
  setProperties: ({ profileProperties, groupProperties } = {}, options) => firebaseAnalytics.setUserProperties({ ...profileProperties, ...groupProperties }, options),
  reset: () => firebaseAnalytics.setUserId(null)
}

// Segment-like Analytics Handler
export const segmentHandler = {
  id: null,
  name: 'Segment',
  logEvent: (name, params, options) => window.analytics.track(name, params, options),
  identify: (id, properties) => {
    segmentHandler.id = id
    segmentHandler.setProperties(properties)
  },
  setProperties: ({ profileProperties, groupProperties } = {}, options) => window.analytics.identify(segmentHandler.id, { ...groupProperties, ...profileProperties }, options),
  reset: () => {
    segmentHandler.id = null
    window.analytics.reset()
  }
}

export const consoleHandler = {
  id: null,
  name: 'Console',
  isActive: localStorage.getItem('showUserDebug') === 'true',
  logEvent: async (name, params, options) => {
    if (!consoleHandler.id) {
      const stableUUID = getStableUUID()
      consoleHandler.identify(stableUUID)
    }
    consoleHandler.log(name, params, options)
  },
  identify: (id, properties) => {
    consoleHandler.id = id
    consoleHandler.setProperties(properties)
  },
  setProperties: ({ profileProperties, groupProperties } = {}, options) => {
    if (!consoleHandler.id) {
      const stableUUID = getStableUUID()
      consoleHandler.identify(stableUUID)
    }
    consoleHandler.log(consoleHandler.id, { ...groupProperties, ...profileProperties }, options)
  },
  reset: () => {
    consoleHandler.id = null
    consoleHandler.log('analytics reset')
  },
  log: (...args) => {
    if (consoleHandler.isActive) {
      // eslint-disable-next-line no-console
      console.info(...args)
    }
  }
}

// Command.ai Handler
export const commandbarHandler = {
  isIdentified: false,
  isShuttingDown: false,
  eventQueue: [],
  propertyQueue: [],
  name: 'CommandBar',

  identify: (id, { profileProperties, groupProperties } = {}) => {
    if (!window.CommandBar) return

    const { isBooted } = window.CommandBar?.shareState() || {}
    const { id: currentId } = window.CommandBar?.shareContext() || {}

    if (commandbarHandler.isShuttingDown) return

    if (isBooted && currentId !== id) {
      commandbarHandler.debugInfo('Restarting user session, anonymous session was found', { currentId, id })
      commandbarHandler.isShuttingDown = true
      window.CommandBar.shutdown()
      setTimeout(() => {
        commandbarHandler.debugInfo('Starting a new identified session and processing saved activity')
        window.CommandBar.boot(id, { ...groupProperties, ...profileProperties })
        commandbarHandler.isShuttingDown = false
        commandbarHandler.isIdentified = true
        commandbarHandler.processEventQueue()
        commandbarHandler.processPropertyQueue()
      }, 3000)
      return
    }

    if (typeof isBooted === 'undefined') {
      commandbarHandler.debugInfo('Waiting for system to load before setting up your identified session')
      setTimeout(() => window.CommandBar.boot(id, { ...groupProperties, ...profileProperties }), 400)
    } else if (!isBooted) {
      commandbarHandler.debugInfo('Setting up your identified session for the first time')
      window.CommandBar.boot(id, { ...groupProperties, ...profileProperties })
    }

    commandbarHandler.isIdentified = true
  },

  setProperties: ({ profileProperties, groupProperties } = {}) => {
    if (!window.CommandBar) return

    if (commandbarHandler.isShuttingDown) {
      commandbarHandler.debugInfo('Queuing user property updates during system update', { profileProperties, groupProperties })
      commandbarHandler.propertyQueue.push({ profileProperties, groupProperties })
      return
    }

    if (commandbarHandler.isIdentified) {
      commandbarHandler.debugInfo(`Updating user properties: \n${ JSON.stringify({ ...groupProperties, ...profileProperties }, null, 2) }`)

      window.CommandBar.setUserProperties({ ...groupProperties, ...profileProperties })
    }
  },

  logEvent: (name) => {
    if (!window.CommandBar) return

    const { isBooted } = window.CommandBar?.shareState() || {}

    if (commandbarHandler.isShuttingDown) {
      commandbarHandler.debugInfo(`Saving your activity "${name}" while the system updates`)
      commandbarHandler.eventQueue.push(name)
      return
    }

    if (typeof isBooted === 'undefined') {
      setTimeout(() => commandbarHandler.logEvent(name), 400)
      return
    }

    if (!commandbarHandler.isIdentified && !isBooted) {
      return
      // commandbarHandler.debugInfo('Setting up your anonymous session for the first time')
      // window.CommandBar.boot(null)
    }

    commandbarHandler.debugInfo(`Tracking your activity: "${name}"`)
    window.CommandBar.track(name, {})
  },

  reset: () => {
    if (!window.CommandBar) return

    if (commandbarHandler.isIdentified) {
      commandbarHandler.debugInfo('Clearing your session and saved activity')
      window.CommandBar.shutdown()
      commandbarHandler.isIdentified = false
      commandbarHandler.isShuttingDown = false
      commandbarHandler.eventQueue = []
      commandbarHandler.propertyQueue = []
    }
  },

  processEventQueue: () => {
    if (!window.CommandBar) return

    if (commandbarHandler.eventQueue.length > 0) {
      commandbarHandler.debugInfo('Completing your saved activities:', commandbarHandler.eventQueue)
      while (commandbarHandler.eventQueue.length) {
        const event = commandbarHandler.eventQueue.shift()
        commandbarHandler.logEvent(event)
      }
    } else {
      commandbarHandler.debugInfo('No saved activities to complete')
    }
  },

  processPropertyQueue: () => {
    if (!window.CommandBar) return

    if (commandbarHandler.propertyQueue.length > 0) {
      commandbarHandler.debugInfo('Processing queued user property updates:', commandbarHandler.propertyQueue)
      while (commandbarHandler.propertyQueue.length) {
        const { profileProperties, groupProperties } = commandbarHandler.propertyQueue.shift()
        commandbarHandler.setProperties({ profileProperties, groupProperties })
      }
    } else {
      commandbarHandler.debugInfo('No queued user property updates to process')
    }
  },

  debugInfo: (...args) => {
    if (!window.CommandBar) return

    if (!window.location.href.includes('app')) {
      // eslint-disable-next-line no-console
      console.info(`[CommandAI Update: ${(new Date()).toLocaleString()}]`, ...args)
    }
  }
}

// Pendo Handler
export const pendoHandler = {
  isInitialized: false,
  name: 'Pendo',
  logEvent: async (name, params) => {
    if (!pendoHandler.isInitialized) {
      return
    }
    window.pendo.track(name, params)
  },
  identify: (id, { profileProperties, groupProperties } = {}) => {
    let idMethod = window.pendo.initialize
    if (pendoHandler.isInitialized) {
      idMethod = window.pendo.updateOptions
    } else {
      pendoHandler.isInitialized = true
    }
    idMethod({
      visitor: { ...profileProperties, id, env },
      account: {
        id,
        ...groupProperties,
        ...(groupProperties?.team_id && { id: groupProperties.team_id }),
        env
      }
    })
  },
  setProperties: ({ profileProperties, groupProperties } = {}) => {
    if (!pendoHandler.isInitialized) {
      return
    }
    window.pendo.updateOptions({ visitor: { ...profileProperties }, account: { ...groupProperties } })
  },
  reset: () => {
    window.pendo.clearSession()
    pendoHandler.isInitialized = false
  }
}
